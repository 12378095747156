export class CookiesUtils {
  static readCookie(name: string) {
    if (typeof document !== 'undefined') {
      const nameEQ = `${name}=`;
      const ca = document.cookie.split(';');
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
    }
    return null;
  }

  static saveCookie(
    name: string,
    value: string,
    neverExpires: boolean = false,
  ) {
    if (typeof document !== 'undefined') {
      let expires = '';

      if (neverExpires) {
        expires = `; expires=${new Date(2038, 0, 1).toUTCString()}`;
      }

      const cookieDomain = process.env.NODE_ENV === 'production' ? 'domain=.trooper.be' : '';
      document.cookie = `${name}=${value}${expires}; path=/;${cookieDomain}`;
    }
  }

  static removeCookie(name: string) {
    if (typeof document !== 'undefined') {
      const cookieDomain = process.env.NODE_ENV === 'production' ? 'domain=.trooper.be' : '';
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;${cookieDomain}`;
    }
  }
}

export class OldUserInfoCookie {
  IsFilled: boolean;

  EmailAddress: string;

  FirstName: string;

  LastName: string;

  AddToTrooperNewsletter: boolean;

  AssociationId: number;

  AssociationName: string;

  ShopId: number;

  UniqueGuid: string;

  constructor(
    emailAddress: string = '',
    firstName: string = '',
    lastName: string = '',
    associationId: number = 0,
    associationName: string = '',
    shopId: number = 0,
    addToTrooperNewsletter: boolean = false,
    uniqueGuid: string = '',
    isFilled: boolean = false,
  ) {
    this.IsFilled = isFilled;
    this.EmailAddress = emailAddress;
    this.FirstName = firstName;
    this.LastName = lastName;
    this.AddToTrooperNewsletter = addToTrooperNewsletter;
    this.ShopId = shopId;
    this.AssociationId = associationId;
    this.UniqueGuid = uniqueGuid;
    this.AssociationName = associationName;
  }
}

export class TrooperUserCookie {
  email!: string;

  firstName!: string;

  lastName!: string;

  shopId!: number;

  associationId!: number;

  associationName!: string;

  uniqueGuid!: string;

  addToNewsletter!: boolean;
}

import * as React from 'react';
import { PageProps, graphql, navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import { associationPageNameCookieKey } from '../utils/cookie-helper';
import { TROOPER_PATHS } from '../../constants';
import { Language } from '../types/enums/language.enum';
import { CookiesUtils } from '../utils/cookies-utils';

function IndexPage({ data, location }: PageProps<Queries.IndexPageQuery>) {
  const noredirect = new URLSearchParams(location.search).has('no-redirect');
  const associationPageName = CookiesUtils.readCookie(associationPageNameCookieKey);

  if (associationPageName && !noredirect) {
    const { language } = useI18next();
    const path = `/${language}/${TROOPER_PATHS[language as Language].association}/${associationPageName}?askFeedback`;
    navigate(path);
  }

  const associationPages = data.siteData.nodes.filter((n) => Object.prototype.hasOwnProperty.call(n.pageContext, 'pageName'));

  return (
    <Layout otherLanguagePath="/">

      <h1>Available association pages:</h1>
      <ul>
        {associationPages.map((ap) => <li key={ap.path}><a href={ap.path}>{ap.path}</a></li>)}
      </ul>

    </Layout>
  );
}

export const query = graphql`
    query IndexPage($language: String!) {
        siteData: allSitePage {
            nodes {
                id
                path
                pageContext
            }
        }
            locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
              edges {
                node {
                  ns
                  data
                  language
                }
              }
            }

    }
`;

export function Head() {
  return <title>Home Page</title>;
}

export default IndexPage;

import {
  OldUserInfoCookie,
  TrooperUserCookie,
} from '../types/trooper-user-cookie.model';
import { CookiesUtils } from './cookies-utils';

const trooperUserKey = 'TrooperUser';
const oldUserInfoKey = 'UserInfoCookie';

export const associationPageNameCookieKey = 'associationPageName';
export const hideRedirectAlertCookieKey = 'hideRedirectAlert';

export class CookieHelper {
  static getTrooperUserCookie(): TrooperUserCookie | null {
    let cookie = CookiesUtils.readCookie(trooperUserKey);
    if (cookie) {
      return this.parseTrooperUserCookie(cookie);
    }
    cookie = CookiesUtils.readCookie(oldUserInfoKey);
    if (cookie) {
      return this.parseOldUserInfoCookie(cookie);
    }

    return null;
  }

  private static parseOldUserInfoCookie(cookie: string): TrooperUserCookie {
    const userInfoCookie = new OldUserInfoCookie();
    // eslint-disable-next-line max-len
    const regex = /(?=IsFilled=|EmailAddress=|FirstName=|LastName=|AddToTrooperNewsletter=|AssociationId=|UniqueGuid=|AssociationName=|ShopId=)(.*?)(?=&IsFilled=|&EmailAddress=|&FirstName=|&LastName=|&AddToTrooperNewsletter=|&AssociationId=|&UniqueGuid=|&AssociationName=|&ShopId=|$)/g;
    const matches = cookie.match(regex);
    matches?.forEach((match) => {
      const parts = match.split('=');
      const property = {
        [parts[0]]: parts[1],
      };

      Object.assign(userInfoCookie, property);
    });

    userInfoCookie.IsFilled = `${userInfoCookie.IsFilled}`.toLowerCase() === 'true';

    return {
      email: userInfoCookie.EmailAddress,
      associationId: userInfoCookie.AssociationId,
      shopId: userInfoCookie.ShopId,
      uniqueGuid: userInfoCookie.UniqueGuid,
      associationName: userInfoCookie.AssociationName,
      firstName: userInfoCookie.FirstName,
      lastName: userInfoCookie.LastName,
      addToNewsletter: userInfoCookie.AddToTrooperNewsletter,
    };
  }

  private static parseTrooperUserCookie(cookie: string): TrooperUserCookie {
    const decodedCookie = decodeURIComponent(cookie);
    const trooperUserCookie = new TrooperUserCookie();
    // eslint-disable-next-line max-len
    const regex = /(?=email=|firstName=|lastName=|addToNewsletter=|associationId=|uniqueGuid=|associationName=|shopId=)(.*?)(?=\|email=|\|firstName=|\|lastName=|\|addToNewsletter=|\|associationId=|\|uniqueGuid=|\|associationName=|\|shopId=|$)/g;
    const matches = decodedCookie.match(regex);
    matches?.forEach((match) => {
      const parts = match.split('=');
      const property = {
        [parts[0]]: parts[1],
      };

      Object.assign(trooperUserCookie, property);
    });

    trooperUserCookie.addToNewsletter = trooperUserCookie.addToNewsletter.toString().toLowerCase() === 'true';

    return trooperUserCookie;
  }
}
